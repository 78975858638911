import React, { useCallback } from 'react'
import Helmet from 'react-helmet'
import PropTypes from 'prop-types'
import Layout from 'layouts/Default'
import PageSection from 'components/Section/pageSection'
import Headline from 'components/Headline'
import Section from 'components/Section'
import { graphql } from 'gatsby'
import styled from 'styled-components'
import { BodyText, PartnerContainer, PartnerTagline } from './styles'
import 'resolvers/ImageGrid/style.css'
import 'resolvers/InlineForm/style.css'
import useInlineFormInteractions from 'templates/partner/useInlineFormInteractions'

const PageSectionStyled = styled(PageSection)`
  overflow: hidden;
`

const Partner = (props) => {
  const {
    name,
    tagline,
    body_text,
  } = props.data.kenticoCloudItemPartner.elements

  const { location, data } = props

  const currentPage = `${data.site.siteMetadata.siteUrl}${location.pathname}`

  useInlineFormInteractions(currentPage)

  const buildOGMetadata = useCallback(() => {
    const { elements } = data.kenticoCloudItemPartner

    return {
      title: elements.page_metadata__opengraph_title
        ? elements.page_metadata__opengraph_title.value
        : undefined,
      imageUrl:
        elements.page_metadata__opengraph_image &&
        elements.page_metadata__opengraph_image.value &&
        elements.page_metadata__opengraph_image[0]
          ? elements.page_metadata__opengraph_image.value[0].url
          : undefined,
      description: elements.page_metadata__opengraph_description
        ? elements.page_metadata__opengraph_description.value
        : undefined,
    }
  })

  return (
    <Layout {...props} ogMetadata={buildOGMetadata()}>
      <Helmet>
        <link
          rel="canonical"
          href={`${process.env.GATSBY_ROBOTS_SITE_URL}${location.pathname}`}
        />
      </Helmet>
      <PageSectionStyled>
        <PartnerContainer>
          <Section pt={90} pb={20}>
            <Headline header>{name.value}</Headline>
            <PartnerTagline>{tagline.value}</PartnerTagline>
            <BodyText
              dangerouslySetInnerHTML={{
                __html: body_text.resolvedHtml,
              }}
            />
          </Section>
        </PartnerContainer>
      </PageSectionStyled>
    </Layout>
  )
}

export default Partner

Partner.propTypes = {
  data: PropTypes.object,
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }),
}

export const query = graphql`
  query partnersQuery($slug: String!) {
    kenticoCloudItemPartner(fields: { slug: { eq: $slug } }) {
      fields {
        slug
      }
      elements {
        name {
          value
        }
        logo {
          name
          type
          value {
            name
            url
          }
        }
        slug {
          value
        }
        page_metadata__opengraph_title {
          name
          value
          type
          text
        }
        page_metadata__opengraph_image {
          value {
            url
          }
        }
        page_metadata__opengraph_description {
          value
        }
        tagline {
          value
        }
        body_text {
          value
          resolvedHtml
        }
      }
    }
    site {
      siteMetadata {
        siteUrl
      }
    }
  }
`
